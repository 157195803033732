import { Badge } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { type Items, type Series, fetcher } from '../../api';
import LoadingScreen from '../LoadingScreen';
import Page from '../Page';
import PageTitle from '../PageTitle';
import { StackedListItem } from '../StackedListItem';

export default function SeriesScreen() {
  const query = useQuery<Items<Series, void>>({
    queryKey: ['api', 'v1', 'series'],
    queryFn: () => fetcher('/api/v1/series'),
  });

  if (query.isLoading) {
    return <LoadingScreen />;
  }

  if (query.isError) {
    return <div>Error!</div>;
  }

  const allSeries = query.data;
  if (!allSeries) {
    return <div>There are no series</div>;
  }

  const series = allSeries.items.map((serial) => {
    return (
      <StackedListItem
        key={serial.id}
        href={`/series/${serial.id}`}
        title={serial.name}
        titleRight={
          <Badge color="gray" variant="light">
            {serial.word_count} words
          </Badge>
        }
        subtitle={
          <Badge color="blue" variant="light">
            {serial.entry_count} posts
          </Badge>
        }
        subtitleRight={
          <Badge color="gray" variant="light">
            {serial.char_count} characters
          </Badge>
        }
      />
    );
  });

  return <Page title={<PageTitle title="All series" />}>{series}</Page>;
}
