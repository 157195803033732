import { Box, Group, Loader, Stack, Text, TextInput, Textarea } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import pluralize from 'pluralize';
import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import Sheet from '../../Sheet.tsx';
import { type Entry, type EntryType, fetcher } from '../../api.ts';
import Editor from './Editor.tsx';

interface EntryEditProps {
  content: string | null;
  id: number | null;
  title: string | null;
  type: EntryType | null;
  serial: number | null;
  tags: number[];
}

export function EntryEdit(props: EntryEditProps) {
  const queryClient = useQueryClient();
  const [id, setId] = useState<number | null>(props.id);
  const [title, setTitle] = useState<string | null>(props.title);
  const [content, setContent] = useState<string>(props.content || '');
  const [contentType, setContentType] = useState<EntryType>(props.type || 'freeform');
  const [serial, setSerial] = useState<number | null>(props.serial);
  const [tags, setTags] = useState<number[]>(props.tags || []);
  const [savedAt, setSavedAt] = useState<Date | null>(null);

  // Whenever the user tries to close the windows when the editor is open, ask to make sure
  // TODO: Be more clever here, and only display the alert if the content has been modified since the last successful save
  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  const createEntry = useMutation({
    mutationFn: () => {
      return fetcher<Entry>('/api/v1/entries', {
        method: 'POST',
        body: JSON.stringify({
          content: content,
          title: title,
          type: contentType,
          series: {
            id: serial,
          },
          tags: [...tags.map((id) => ({ id: id }))],
        }),
      });
    },
    onSuccess: async (entry) => {
      setId(entry.id);
      await queryClient.invalidateQueries({
        queryKey: ['api', 'v1', 'entries', id],
      });
      setSavedAt(new Date());
    },
  });

  const patchEntry = useMutation({
    mutationFn: (patch: object[]) => {
      return fetcher(`/api/v1/entries/${id}`, {
        method: 'PATCH',
        body: JSON.stringify(patch),
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['api', 'v1', 'entries', id],
      });

      setSavedAt(new Date());
    },
  });

  // TODO: Also fire this on page unload (or whatever event it is when the user closes the page)

  const updateContent = async (value: string) => {
    if (value !== undefined) {
      if (id === null) {
        await createEntry.mutateAsync();
      } else {
        await patchEntry.mutateAsync([
          {
            op: 'replace',
            path: '/content',
            value: value,
          },
          {
            op: 'replace',
            path: '/title',
            value: title,
          },
          {
            op: 'replace',
            path: '/type',
            value: contentType,
          },
        ]);
      }
    }
  };

  const updateTitle = async (value: string) => {
    if (id) {
      await patchEntry.mutateAsync([
        {
          op: 'replace',
          path: '/title',
          value: value,
        },
      ]);
    }
  };

  const debounceUpdateContent = useDebouncedCallback(updateContent, 1000, {
    maxWait: 5000,
  });

  const debounceUpdateTitle = useDebouncedCallback(updateTitle, 1000, {
    maxWait: 5000,
  });

  const onChangeContent = useCallback(
    async (newContent: string) => {
      setContent(newContent);
      debounceUpdateContent(newContent);
    },
    [debounceUpdateContent],
  );

  const onChangeTitle = useCallback(
    async (newTitle: string) => {
      setTitle(newTitle);
      debounceUpdateTitle(newTitle);
    },
    [debounceUpdateTitle],
  );

  // Whenever the user switches tabs, or otherwise hides the window (i.e. mobile browsers), save the content
  useEffect(() => {
    window.addEventListener('visibilitychange', async () => {
      console.log('visibilitychange', document.visibilityState);

      if (document.visibilityState === 'hidden') {
        await onChangeContent(content);
      }

      if (document.visibilityState === 'visible') {
        // TODO
      }
    });
    return () => {
      window.removeEventListener('visibilitychange', handleUnload);
    };
  }, [content, onChangeContent]);

  const handleUnload = async (e: Event) => {
    e.preventDefault();
    // await onChangeContent(content);
  };

  // TODO: Implement mobile-friendly unload handling: https://www.igvita.com/2015/11/20/dont-lose-user-and-app-state-use-page-visibility/

  const wordCount = content.trim().length === 0 ? 0 : content.split(/\s+\b/).length;

  // TODO: Notify if the date crosses over into tomorrow (or past the user's set "end of day" threshold)
  // TODO: Store content in local storage or something until it's saved

  const onChangeSerial = async (serial: number | null) => {
    setSerial(serial);

    if (id) {
      await patchEntry.mutateAsync([
        serial ? { op: 'replace', path: '/series', value: { id: serial } } : { op: 'remove', path: '/series' },
      ]);
    }
  };

  const onChangeTags = async (tags: number[]) => {
    setTags(tags);

    if (id) {
      await patchEntry.mutateAsync([
        {
          op: 'replace',
          path: '/tags',
          value: tags.map((tag) => ({ id: tag })),
        },
      ]);
    }
  };

  const saving = createEntry.isPending || patchEntry.isPending;
  const savingElement = saving ? (
    <Loader size={16} />
  ) : savedAt ? (
    // TODO: If saving errors, this says the wrong thing!
    <span>Saved at {savedAt.toLocaleTimeString()}</span>
  ) : (
    <span>Not saved yet</span>
  );

  const editor = (
    <Stack gap={8}>
      <Sheet p={8} shade="dark">
        <Text size="sm">
          <Group align="center" justify="space-between">
            <Box>
              {wordCount} {pluralize('word', wordCount)}
            </Box>
            <Box>{savingElement}</Box>
          </Group>
        </Text>
      </Sheet>

      <TextInput
        name="title"
        placeholder="Enter a title for your entry (optional)"
        value={title || undefined}
        onChange={(e) => onChangeTitle(e.target.value)}
      />

      <Box>
        {/* TODO: There is a focus outline here for some reason */}
        <Textarea
          id="typewriter"
          autoFocus
          autoComplete="off"
          autosize
          defaultValue={content}
          onChange={(e) => onChangeContent(e.target.value)}
          placeholder="Start writing something..."
          radius="md"
          // TODO: Height should be 100% - height of toolbar
          minRows={10}
        />
      </Box>
    </Stack>
  );

  return (
    <Editor
      onChangeTags={onChangeTags}
      onChangeSeries={onChangeSerial}
      onChangeType={setContentType}
      serial={serial}
      tags={tags}
      type={contentType}
    >
      {editor}
    </Editor>
  );
}
