import { Button, Title } from '@mantine/core';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import type { Entry } from '../../api.ts';

interface EntryViewProps {
  entry?: Entry;
}

export const EntryView = ({ entry }: EntryViewProps) => {
  if (entry) {
    return (
      <>
        <Button component={Link} to={`/entries/${entry.id}/edit`}>
          Edit
        </Button>

        <Title order={3} mt={8}>
          {entry.word_count} {pluralize('word', entry.word_count)}
        </Title>
        {entry.content}
      </>
    );
  }

  // TODO: This is basically the 404 screen now
  return (
    <>
      <h3>🙁 Nothing here</h3>
      <p>
        You didn't make an entry on this date! Make sure you've <Link to="/"> written something for today</Link>.
      </p>
    </>
  );
};
