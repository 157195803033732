import { Badge, rgba } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import pluralize from 'pluralize';
import { type Items, type Tag, fetcher } from '../../api';
import LoadingScreen from '../LoadingScreen';
import Page from '../Page';
import PageTitle from '../PageTitle';
import { StackedListItem } from '../StackedListItem';

export default function TagsScreen() {
  const query = useQuery<Items<Tag, void>>({
    queryKey: ['api', 'v1', 'tags'],
    queryFn: () => fetcher('/api/v1/tags'),
  });

  if (query.isLoading) {
    return <LoadingScreen />;
  }

  if (query.isError) {
    return <div>Error!</div>;
  }

  const allTags = query.data;
  if (!allTags) {
    return <div>There are no tags</div>;
  }

  const tags = allTags.items.map((tag) => {
    const title = (
      <Badge
        style={{ backgroundColor: tag.colour_background, color: tag.colour_foreground }}
        variant="outline"
        styles={{
          root: {
            borderColor: rgba(tag.colour_foreground, 0.2),
          },
        }}
      >
        {tag.name}
      </Badge>
    );

    return (
      <StackedListItem
        key={tag.id}
        href={`/tags/${tag.id}`}
        title={title}
        titleRight={
          <Badge color="gray" variant="light">
            {tag.word_count} {pluralize('word', tag.word_count)}
          </Badge>
        }
        subtitle={
          <Badge color="blue" variant="light">
            {tag.entry_count} {pluralize('post', tag.entry_count)}
          </Badge>
        }
        subtitleRight={
          <Badge color="gray" variant="light">
            {tag.char_count} {pluralize('character', tag.char_count)}
          </Badge>
        }
      />
    );
  });

  return <Page title={<PageTitle title="All tags" />}>{tags}</Page>;
}
