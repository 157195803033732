import { BookOpenIcon, CheckCircleIcon, PencilIcon, TrophyIcon } from '@heroicons/react/24/outline';
import { useMantineTheme } from '@mantine/core';
import ActionGrid, { type Action } from '../ActionGrid.tsx';
import Page from '../Page';

export default function TodayScreen() {
  const theme = useMantineTheme();

  const actions: Action[] = [
    {
      title: 'Write an entry',
      description: 'Start a new entry for today',
      href: '/entries/new',
      icon: PencilIcon,
      iconForeground: theme.colors.blue[6],
      iconBackground: theme.colors.blue[6],
    },
    {
      title: 'Work on a series',
      description: 'Write and manage a series of entries',
      href: '/series',
      icon: BookOpenIcon,
      iconForeground: theme.colors.green[6],
      iconBackground: theme.colors.green[6],
    },
    {
      title: 'Review entries',
      description: 'Review and edit previous entries',
      href: '/entries',
      icon: CheckCircleIcon,
      iconForeground: theme.colors.yellow[6],
      iconBackground: theme.colors.yellow[6],
    },
    {
      title: 'Check your goals',
      description: 'Review and edit your goals',
      href: '/goals',
      icon: TrophyIcon,
      iconForeground: theme.colors.red[6],
      iconBackground: theme.colors.red[6],
    },
  ];

  return (
    <Page maxWidth="sm" title={<div />}>
      <ActionGrid actions={actions} />
    </Page>
  );
}
