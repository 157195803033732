import { PlusIcon } from '@heroicons/react/16/solid';
import { EllipsisHorizontalCircleIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon, ExclamationTriangleIcon, PencilIcon, XCircleIcon } from '@heroicons/react/24/solid';
import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Group,
  type MantineColor,
  Paper,
  Progress,
  Stack,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { format, isEqual, startOfMonth, startOfWeek } from 'date-fns';
import pluralize from 'pluralize';
import type React from 'react';
import { Link } from 'react-router-dom';
import { type Goal, type Items, fetcher } from '../../api.ts';
import { formatDate, getToday } from '../../utils.ts';
import Page from '../Page';
import PageTitle from '../PageTitle';

export default function GoalsScreen() {
  const query = useQuery<Items<Goal, void>>({
    queryKey: ['api', 'v1', 'goals'],
    queryFn: () => fetcher('/api/v1/goals'),
  });

  if (query.isError) {
    // TODO
    return <p>Error</p>;
  }

  const goals = query.data;
  if (!goals) {
    // TODO
    return <p>Loading...</p>;
  }

  return (
    <Page
      title={
        <Group align="center" justify="space-between">
          <PageTitle title="Goals" />

          <Box>
            <Button component={Link} variant="light" leftSection={<PlusIcon width={16} />} to="/goals/new">
              Set a new goal
            </Button>
          </Box>
        </Group>
      }
      loading={query.isLoading}
    >
      <Alert color="yellow" title="Warning" icon={<ExclamationTriangleIcon />} mb={16}>
        Goals are still in development and may not work as expected!
      </Alert>

      <Stack gap={0}>
        {goals.items.map((goal) => (
          <GoalItem key={goal.id} goal={goal} />
        ))}
      </Stack>
    </Page>
  );
}

interface GoalItemProps {
  goal: Goal;
}

const GoalItem = ({ goal }: GoalItemProps) => {
  switch (goal.type) {
    case 'project_completion':
      return <ProjectCompletionGoalItem goal={goal} />;
    case 'word_count':
      return <WordCountGoalItem goal={goal} />;
    case 'entry_count':
      return <EntryCountGoalItem goal={goal} />;
    default:
      // TODO
      return <></>;
  }
};

interface ProjectCompletionGoalItemProps {
  goal: Goal;
}

const ProjectCompletionGoalItem = ({ goal }: ProjectCompletionGoalItemProps) => {
  // TODO: This component should display whether the project was completed after the deadline or not
  const isCompleted = goal.progress.length > 0;

  const actions = (
    <Tooltip label={`Mark as ${isCompleted ? 'incomplete' : 'complete'}`}>
      <ActionIcon p={4} variant="subtle" color="gray" size="lg">
        {isCompleted ? <XCircleIcon /> : <CheckCircleIcon />}
      </ActionIcon>
    </Tooltip>
  );

  return (
    <ProgressCard
      actions={actions}
      completed={isCompleted}
      progress={isCompleted ? 100 : 0}
      title={`Finish the project ${goal.target} by ${goal.ends_at}`}
      subtitle={isCompleted ? `Completed on ${formatDate(goal.progress[0].date)}` : 'Not completed yet'}
    />
  );
};

interface WordCountGoalItemProps {
  goal: Goal;
}

const WordCountGoalItem = ({ goal }: WordCountGoalItemProps) => {
  const today = new Date(format(new Date(getToday()), 'yyyy-MM-dd'));
  const targetWordCount = Number.parseInt(goal.target);

  let currentWordCount = 0;
  if (goal.recurrence === 'daily') {
    const todaysProgress = goal.progress.find((p) => isEqual(new Date(p.date), today));
    currentWordCount = todaysProgress?.count || 0;
  } else if (goal.recurrence === 'weekly') {
    const weekStart = startOfWeek(today, { weekStartsOn: 1 });
    const thisWeek = goal.progress.find((p) => isEqual(new Date(p.date), weekStart));
    currentWordCount = thisWeek?.count || 0;
  } else if (goal.recurrence === 'monthly') {
    const monthStart = startOfMonth(today);
    const thisMonth = goal.progress.find((p) => isEqual(new Date(p.date), monthStart));
    currentWordCount = thisMonth?.count || 0;
  }

  const progress = Math.min(100, (100 / targetWordCount) * currentWordCount);
  const isCompleted = currentWordCount >= targetWordCount;

  return (
    <ProgressCard
      completed={isCompleted}
      progress={progress}
      title={`Write ${goal.target} words per ${convertToRecurrence(goal.recurrence)}`}
      subtitle={`${currentWordCount}/${targetWordCount} words so far ${convertToCurrentTimeRange(goal.recurrence)}`}
    />
  );
};

interface ProgressCardProps {
  actions?: React.ReactNode;
  completed: boolean;
  progress: number;
  title: string;
  subtitle: string;
}

function ProgressCard({ actions, completed, progress, title, subtitle }: ProgressCardProps) {
  const theme = useMantineTheme();

  const getProgressColor = (progress: number): MantineColor => {
    if (progress === 100) {
      return theme.colors.green[9];
    }

    if (progress > 25) {
      return theme.colors.yellow[9];
    }

    return theme.colors.red[9];
  };

  return (
    <Paper mb={12} p={24} radius="md" withBorder variant="outlined">
      <Group justify="space-between">
        <Group gap={8}>
          {completed ? (
            <CheckCircleIcon color={theme.colors.green[9]} width={24} />
          ) : (
            <EllipsisHorizontalCircleIcon color={theme.colors.gray[6]} width={24} />
          )}

          <Title order={4}>{title}</Title>
        </Group>

        <Group gap={2}>
          {actions}

          <Tooltip label="Edit goal">
            <ActionIcon p={5} variant="subtle" color="gray" size="lg">
              <PencilIcon />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Group>

      <Text size="sm">{subtitle}</Text>

      <Box mt={12}>
        <Progress value={progress} color={getProgressColor(progress)} variant="soft" />
      </Box>
    </Paper>
  );
}

interface EntryCountGoalItemProps {
  goal: Goal;
}

const EntryCountGoalItem = ({ goal }: EntryCountGoalItemProps) => {
  const today = new Date(format(new Date('2024-01-08'), 'yyyy-MM-dd'));
  const target = Number.parseInt(goal.target);

  let current = 0;
  if (goal.recurrence === 'daily') {
    const thisDay = goal.progress.find((p) => isEqual(new Date(p.date), today));
    current = thisDay?.count || 0;
  } else if (goal.recurrence === 'weekly') {
    const weekStart = startOfWeek(today, { weekStartsOn: 1 });
    const thisWeek = goal.progress.find((p) => isEqual(new Date(p.date), weekStart));
    current = thisWeek?.count || 0;
  } else if (goal.recurrence === 'monthly') {
    const monthStart = startOfMonth(today);
    const thisMonth = goal.progress.find((p) => isEqual(new Date(p.date), monthStart));
    current = thisMonth?.count || 0;
  }

  const progress = Math.min(100, (100 / target) * current);
  const isCompleted = current >= target;

  return (
    <ProgressCard
      completed={isCompleted}
      progress={progress}
      title={`Complete ${goal.target} ${pluralize('entry', target)} per ${convertToRecurrence(goal.recurrence)}`}
      subtitle={`${current}/${target} ${pluralize('entry', target)} so far ${convertToCurrentTimeRange(goal.recurrence)}`}
    />
  );
};

function convertToRecurrence(recurrence: string): string {
  if (recurrence === 'daily') {
    return 'day';
  }

  if (recurrence === 'weekly') {
    return 'week';
  }

  if (recurrence === 'monthly') {
    return 'month';
  }

  // TODO
  return '';
}

function convertToCurrentTimeRange(recurrence: string): string {
  if (recurrence === 'daily') {
    return 'today';
  }

  if (recurrence === 'weekly') {
    return 'this week';
  }

  if (recurrence === 'monthly') {
    return 'this month';
  }

  // TODO
  return '';
}
