import { Paper } from '@mantine/core';
import type React from 'react';

type Props = typeof Paper.defaultProps & {
  children: React.ReactNode;
  shade: 'dark' | 'light';
};

export default function Sheet({ children, shade, ...props }: Props) {
  const background = shade === 'dark' ? '#f0f4f8' : '#fbfcfe';

  return (
    <Paper radius="md" withBorder bg={background} {...props}>
      {children}
    </Paper>
  );
}
