import { fetchAuthSession } from 'aws-amplify/auth';

const createFetchOptions = async (args: RequestInit = {}): Promise<RequestInit> => {
  const { accessToken } = (await fetchAuthSession()).tokens ?? {};

  return {
    ...args,
    headers: {
      ...args.headers,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

class ApiError extends Error {
  status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

export const fetcher = async <Data>(uri: RequestInfo, args?: RequestInit): Promise<Data> => {
  const options = await createFetchOptions(args);
  const response = await fetch(uri, options);

  if (!response.ok) {
    throw new ApiError('An error occurred while fetching the data.', response.status);
  }

  return response.json();
};

interface GoalProgress {
  date: string;
  completed: boolean;
  count?: number;
}

export interface Goal {
  id: number;
  type: 'word_count' | 'entry_count' | 'project_completion';
  target: string;
  recurrence: string;
  starts_at: string;
  ends_at: string;
  progress: GoalProgress[];
}

export interface Stats {
  current_streak: number;
  longest_streak: number;
  total_entries: number;
  total_words: number;
  shortest_entry: number;
  longest_entry: number;
}

interface TagEntry {
  id: number;
  title: string;
  date: string;
  extract: string;
  word_count: number;
  char_count: number;
  created_at: string;
}

export interface Tag {
  id: number;
  name: string;
  colour_background: string;
  colour_foreground: string;
  word_count: number;
  char_count: number;
  entry_count: number;
  entries: TagEntry[];
}

export interface Prompt {
  text: string;
}

export interface Settings {
  reminder: number;
}

export interface EntryMeta {
  entries_total: number;
  entries_missing: number;
  entries_words: number;
}

export interface EntrySeries {
  id: number;
  name: string;
}

export interface EntryTag {
  id: number;
  name: string;
  colour_background: string;
  colour_foreground: string;
}

export type EntryType = 'freeform' | 'prompt';

export interface EntryListEntry {
  id: number;
  title: string;
  date: string;
  extract: string;
  word_count: number;
  tags: EntryTag[];
  series: EntrySeries;
  created_at: string;
}

export interface Entry {
  id: number;
  type: EntryType;
  title: string;
  body: string;
  date: string;
  content: string;
  word_count: number;
  char_count: number;
  series: EntrySeries;
  tags: EntryTag[];
}

interface SeriesEntry {
  id: number;
  title: string;
  date: string;
  extract: string;
  word_count: number;
  char_count: number;
  created_at: string;
}

export interface Series {
  id: number;
  name: string;
  description: string;
  entry_count: number;
  word_count: number;
  char_count: number;
  entries: SeriesEntry[];
}

export interface Items<T, M> {
  meta: M;
  items: T[];
}
