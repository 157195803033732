import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { type Series, fetcher } from '../../api';
import EntryListItem from '../EntryListItem.tsx';
import LoadingScreen from '../LoadingScreen';
import Page from '../Page';
import PageTitle from '../PageTitle';

type SerialScreenParams = {
  id: string;
};

export default function SerialScreen() {
  const { id } = useParams<SerialScreenParams>() as SerialScreenParams;
  const query = useQuery<Series>({
    queryKey: ['api', 'v1', 'series', id],
    queryFn: () => fetcher(`/api/v1/series/${id}`),
  });

  if (query.isLoading) {
    return <LoadingScreen />;
  }

  if (query.isError) {
    return <div>Error!</div>;
  }

  const serial = query.data;
  if (!serial) {
    return <div>Serial not found</div>;
  }

  return (
    <Page title={<PageTitle title={serial.name} />}>
      {serial.entries.map((entry) => {
        return <EntryListItem key={entry.id} entry={entry} />;
      })}
    </Page>
  );
}
