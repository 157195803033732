import { PlusIcon } from '@heroicons/react/16/solid';
import { Button, Group, Modal, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { type Items, type Series, fetcher } from '../../api.ts';
import FormSubmit from '../FormSubmit.tsx';

interface AddSerialRequest {
  name: string;
}

interface AddSerialModalProps {
  onSave: (id: number) => void;
}

export function AddSerialModal({ onSave }: AddSerialModalProps) {
  const queryClient = useQueryClient();
  const [opened, { open, close }] = useDisclosure(false);

  const initialValues: AddSerialRequest = {
    name: '',
  };

  const form = useForm({
    mode: 'uncontrolled',
    initialValues,
    validate: {
      name: (value) => (!value ? 'Required' : null),
    },
  });

  const createSerial = useMutation({
    mutationFn: (values: typeof form.values) => {
      return fetcher<Series>('/api/v1/series', {
        method: 'POST',
        body: JSON.stringify({
          name: values.name,
        }),
      });
    },
    onError: (error) => {
      console.error(error);
      notifications.show({
        title: 'Error',
        message: 'Failed to add serial. Please try again!',
        color: 'red',
      });
    },
    onSuccess: async (serial) => {
      form.reset();
      await queryClient.invalidateQueries({
        queryKey: ['api', 'v1', 'series'],
      });
      onSave(serial.id);
      close();
    },
  });

  return (
    <>
      <Button variant="subtle" leftSection={<PlusIcon width={16} />} onClick={open}>
        Add
      </Button>

      <Modal centered opened={opened} onClose={close} title="Add new serial">
        <form onSubmit={form.onSubmit((e) => createSerial.mutate(e))}>
          <Group grow preventGrowOverflow={false} justify="space-between">
            <TextInput data-autofocus key={form.key('name')} {...form.getInputProps('name')} />
            <FormSubmit loading={createSerial.isPending}>Save</FormSubmit>
          </Group>
        </form>
      </Modal>
    </>
  );
}

interface SelectSerialProps {
  onChange: (id: number | null) => void;
  value: number | null;
}

export default function SelectSerial({ onChange, value }: SelectSerialProps) {
  const [selected, setSelected] = useState<number | null>(value);
  const query = useQuery<Items<Series, void>>({
    queryKey: ['api', 'v1', 'series'],
    queryFn: () => fetcher('/api/v1/series'),
  });

  if (query.isError) {
    // TODO
    return <div>Error!</div>;
  }

  if (query.isLoading) {
    // TODO
    return <div>Loading...</div>;
  }

  const series = query.data;
  if (!series) {
    // TODO
    return <div>Something went wrong</div>;
  }

  const onChangeSerial = (id: number | null) => {
    setSelected(id);
    onChange(id);
  };

  const options = series?.items.map((t) => ({
    value: t.id.toString(),
    label: t.name,
  }));

  return (
    <Group grow preventGrowOverflow={true}>
      <Select
        clearable
        data={options}
        onChange={(v) => onChangeSerial(v ? Number.parseInt(v) : null)}
        placeholder="Pick a serial"
        value={selected?.toString()}
      />

      <AddSerialModal onSave={(id) => onChangeSerial(id)} />
    </Group>
  );
}
