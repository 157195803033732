import { TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import FormActions from '../FormActions';
import FormFieldset from '../FormFieldset';
import FormSubmit from '../FormSubmit';
import Page from '../Page';
import PageTitle from '../PageTitle';

export default function ProfileScreen() {
  const [isSubmitting, setSubmitting] = useState(false);
  const { loading, attributes, updateAttributes } = useAuth();

  const form = useForm({
    mode: 'uncontrolled',
  });

  useEffect(() => {
    form.setValues({
      name: attributes.name || '',
      email: attributes.email || '',
    });
  }, [attributes, form]);

  const onSubmit = async (values: typeof form.values) => {
    setSubmitting(true);
    try {
      await updateAttributes(values);
    } catch (error) {
      // TODO
      console.log(error);
      notifications.show({
        title: 'Error',
        message: 'An error occurred while saving your profile. Please try again!',
        color: 'red',
      });
    }

    setSubmitting(false);
    notifications.show({
      title: 'Profile saved',
      message: 'Your profile was saved!',
      color: 'green',
    });
  };

  return (
    <Page loading={loading} title={<PageTitle title="Your profile" />}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <FormFieldset>
          <TextInput
            key={form.key('name')}
            {...form.getInputProps('name')}
            label="Name"
            description="What should we call you?"
            disabled={isSubmitting || loading}
          />

          <TextInput key={form.key('email')} {...form.getInputProps('email')} label="Email" disabled={true} />
        </FormFieldset>

        <FormActions>
          <FormSubmit loading={isSubmitting || loading}>Save</FormSubmit>
        </FormActions>
      </form>
    </Page>
  );
}
