import type { UseAuthenticator } from '@aws-amplify/ui-react';
import { ArrowLeftStartOnRectangleIcon, MoonIcon, SunIcon, UserCircleIcon } from '@heroicons/react/16/solid';
import { NewspaperIcon } from '@heroicons/react/24/outline';
import { PencilIcon } from '@heroicons/react/24/solid';
import {
  ActionIcon,
  AppShell,
  Box,
  Burger,
  Button,
  Group,
  Menu,
  NavLink,
  Text,
  Tooltip,
  useMantineColorScheme,
} from '@mantine/core';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

function ColorSchemeToggle() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return <ActionIcon variant="outline" />;
  }
  return (
    <Tooltip label="Change theme">
      <ActionIcon variant="subtle" color="gray" onClick={() => toggleColorScheme()}>
        {colorScheme === 'light' ? <MoonIcon width={24} /> : <SunIcon width={24} />}
      </ActionIcon>
    </Tooltip>
  );
}

interface HeaderProps {
  navbarOpened: boolean;
  navbarToggle: () => void;
  signOut: UseAuthenticator['signOut'];
}

export default function Header({ navbarOpened, navbarToggle, signOut }: HeaderProps) {
  const location = useLocation();
  const { attributes } = useAuth();
  if (!attributes) {
    return <></>;
  }

  const navigation = [
    { name: 'Today', href: '/' },
    { name: 'Entries', href: '/entries' },
    { name: 'Series', href: '/series' },
    { name: 'Tags', href: '/tags' },
    { name: 'Stats', href: '/stats' },
    { name: 'Goals', href: '/goals' },
  ];

  const userNavigation = [
    { name: 'Your Profile', href: '/profile' },
    { name: 'Settings', href: '/settings' },
  ];

  const handleLogout = async () => {
    await signOut();
  };

  return (
    <>
      <AppShell.Header>
        <Group h="100%" justify="space-between" px="md" w="100%">
          <Box>
            <Burger
              hiddenFrom="sm"
              opened={navbarOpened}
              onClick={navbarToggle}
              aria-label="Toggle navigation"
              size="sm"
            />

            <Group visibleFrom="sm">
              <Group justify="center" align="center" gap={8}>
                <ActionIcon
                  component={Link}
                  to="/"
                  size="xl"
                  variant="subtle"
                  p={8}
                  color="neutral"
                  visibleFrom="sm"
                  radius="xl"
                >
                  <NewspaperIcon />
                </ActionIcon>

                {navigation.map((item) => {
                  const current = location.pathname === item.href;

                  return (
                    <Button
                      key={item.name}
                      variant={current ? 'light' : 'subtle'}
                      color="dark"
                      component={Link}
                      to={item.href}
                      radius="md"
                      size="sm"
                    >
                      {item.name}
                    </Button>
                  );
                })}
              </Group>

              <Button
                component={Link}
                to="/entries/new"
                variant="light"
                color="blue"
                size="sm"
                leftSection={<PencilIcon width="16" />}
              >
                New Entry
              </Button>
            </Group>
          </Box>

          <Group gap={8}>
            <ColorSchemeToggle />
            <Menu>
              <Menu.Target>
                <ActionIcon size="md" variant="subtle" color="gray">
                  <UserCircleIcon width={24} />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item leftSection={<UserCircleIcon width={16} />}>
                  <Box>
                    <Text size="sm" fw={500}>
                      {attributes.name}
                    </Text>
                    <Text size="xs" c="dimmed">
                      {attributes.email}
                    </Text>
                  </Box>
                </Menu.Item>

                <Menu.Divider />

                {userNavigation.map((item) => (
                  <Menu.Item key={item.name} component={Link} to={item.href}>
                    {item.name}
                  </Menu.Item>
                ))}

                <Menu.Divider />

                <Menu.Item onClick={handleLogout} leftSection={<ArrowLeftStartOnRectangleIcon width={16} />}>
                  Log out
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Group>
      </AppShell.Header>

      <AppShell.Navbar py="md" px={4}>
        {navigation.map((item) => {
          const current = location.pathname === item.href;

          return (
            <NavLink
              key={item.name}
              variant="filled"
              active={current}
              component={Link}
              label={item.name}
              to={item.href}
            />
          );
        })}
      </AppShell.Navbar>
    </>
  );
}
