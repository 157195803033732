/**
 * Format date in the local format.
 *
 * @param date
 * @returns {string}
 */
export function formatDate(date: string): string {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
}

/**
 * Get today's date in YYYY-MM-DD format.
 *
 * @returns {string}
 */
export function getToday(): string {
  const today = new Date();
  return today.toISOString().slice(0, 10);
}

/**
 * Get yesterday's date in YYYY-MM-DD format.
 *
 * @returns {string}
 */
export function getDayBefore(date: string): string {
  const dayBefore = new Date(date);
  dayBefore.setDate(dayBefore.getDate() - 1);
  return dayBefore.toISOString().slice(0, 10);
}

/**
 * Get tomorrow's date in YYYY-MM-DD format.
 *
 * @returns {string}
 */
export function getDayAfter(date: string) {
  const dayAfter = new Date(date);
  dayAfter.setDate(dayAfter.getDate() + 1);
  return dayAfter.toISOString().slice(0, 10);
}
