import { Group, Stack, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { type EntryListEntry, type EntryMeta, type Items, fetcher } from '../../api';
import EntryListItem from '../EntryListItem.tsx';
import LoadingScreen from '../LoadingScreen';
import Page from '../Page';
import PageTitle from '../PageTitle';

export default function EntriesScreen() {
  const query = useQuery<Items<EntryListEntry, EntryMeta>>({
    queryKey: ['api', 'v1', 'entries'],
    queryFn: () => fetcher('/api/v1/entries'),
  });

  if (query.isLoading) {
    return <LoadingScreen />;
  }

  if (query.isError) {
    return <div>Error loading entries</div>;
  }

  if (!query.data) {
    return <div>There are no entries</div>;
  }

  const entries = query.data.items.map((entry) => {
    return <EntryListItem key={entry.id} entry={entry} />;
  });

  // TODO: This makes the header a bit taller than it is on the other pages
  const titleExtra = (
    <Group align="center">
      <Stack gap={2}>
        <Text size="xs" c="dimmed" fw="500">
          Total Entries
        </Text>

        <Text size="md">{query.data.meta.entries_total}</Text>
      </Stack>
      <Stack gap={2}>
        <Text size="xs" c="dimmed" fw="500">
          # of Words
        </Text>

        <Text size="md">{query.data.meta.entries_words}</Text>
      </Stack>
    </Group>
  );

  return (
    <Page
      title={
        <Group justify="space-between" align="center">
          <PageTitle title="All entries" />

          {titleExtra}
        </Group>
      }
    >
      {entries}
    </Page>
  );
}
