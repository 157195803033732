import { ActionIcon, Box, type MantineColor, SimpleGrid, Stack, Text, Title } from '@mantine/core';
import type React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Sheet from '../Sheet.tsx';

export interface Action {
  title: string;
  description: string;
  href: string;
  icon: React.ElementType;
  iconForeground: MantineColor;
  iconBackground: MantineColor;
}

interface ActionGridProps {
  actions: Action[];
}

export default function ActionGrid({ actions }: ActionGridProps) {
  return (
    <SimpleGrid cols={{ base: 1, sm: 2 }}>
      {actions.map((action) => {
        const content = (
          <Stack>
            <ActionIcon color={action.iconBackground} size="xl" variant="light">
              <action.icon width={24} />
            </ActionIcon>

            <Box>
              <Title order={4} pt={3}>
                {action.title}
              </Title>
              <Text size="sm" py={2}>
                {action.description}
              </Text>
            </Box>
          </Stack>
        );

        return (
          <Sheet key={action.title} p={24} shade="light">
            <RouterLink to={action.href}>{content}</RouterLink>
          </Sheet>
        );
      })}
    </SimpleGrid>
  );
}
