import { Square3Stack3DIcon } from '@heroicons/react/16/solid';
import { Badge, Group, Text, Tooltip, rgba } from '@mantine/core';
import { format } from 'date-fns';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import type { EntrySeries, EntryTag } from '../api.ts';
import { StackedListItem } from './StackedListItem.tsx';

interface Props {
  entry: {
    id: number;
    title: string | null;
    extract: string;
    date: string;
    word_count: number;
    tags?: EntryTag[];
    series?: EntrySeries;
    created_at: string;
  };
}

function generateTitle(entry: Props['entry']) {
  if (entry.title) {
    return `${entry.date}: ${entry.title}`;
  }

  return entry.date;
}

export default function EntryListItem({ entry }: Props) {
  return (
    <StackedListItem
      href={`/entries/${entry.id}`}
      // TODO: This title not descriptive or precise enough
      title={
        <Tooltip label={`Created ${format(entry.created_at, "'around' h a 'on' EEEE do MMMM yyyy")}`}>
          <span>{generateTitle(entry)}</span>
        </Tooltip>
      }
      titleRight={
        <Badge color="gray" radius="sm" variant="light">
          {entry.word_count} {pluralize('word', entry.word_count)}
        </Badge>
      }
      subtitleRight={<EntryTags tags={entry.tags} />}
      subtitle={<EntrySeriesChip series={entry.series} />}
    >
      <Text size="sm" lineClamp={2}>
        {entry.extract}
      </Text>
    </StackedListItem>
  );
}

interface EntryTagsProps {
  tags?: EntryTag[];
}

const EntryTags = ({ tags }: EntryTagsProps) => {
  if (!tags || tags.length === 0) {
    return <></>;
  }

  return (
    <Group gap={8}>
      {tags.map((tag) => (
        <Badge
          key={tag.id}
          component={Link}
          to={`/tags/${tag.id}`}
          radius="sm"
          style={{ backgroundColor: tag.colour_background, color: tag.colour_foreground }}
          variant="outline"
          styles={{
            root: {
              borderColor: rgba(tag.colour_foreground, 0.2),
            },
          }}
        >
          {tag.name}
        </Badge>
      ))}
    </Group>
  );
};

interface EntrySeriesChipProps {
  series?: EntrySeries;
}

const EntrySeriesChip = ({ series }: EntrySeriesChipProps) => {
  // TODO: Figure out why this is an empty object when no series is assigned
  if (!series || !series.id) {
    return <></>;
  }

  return (
    <>
      <Badge color="gray" radius="sm" variant="light" leftSection={<Square3Stack3DIcon width={16} />}>
        Part of the{' '}
        <Link to={`/series/${series.id}`}>
          <strong>{series.name}</strong>
        </Link>{' '}
        series
      </Badge>
    </>
  );
};
