import { InformationCircleIcon } from '@heroicons/react/16/solid';
import { PencilIcon, SparklesIcon } from '@heroicons/react/24/solid';
import { Box, Button, Grid, Group, Stack, Text, Tooltip } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import type React from 'react';
import { type EntryType, type Prompt, fetcher } from '../../api.ts';
import LoadingScreen from '../LoadingScreen';
import SelectSerial from './SelectSerial.tsx';
import SelectTags from './SelectTags.tsx';

interface EditorProps {
  children: React.ReactNode;
  onChangeTags: (value: number[]) => void;
  onChangeSeries: (value: number | null) => void;
  onChangeType: (value: EntryType) => void;
  serial: number | null;
  tags: number[];
  type: EntryType;
}

export default function Editor({
  children,
  onChangeTags,
  onChangeSeries,
  onChangeType,
  serial,
  tags,
  type,
}: EditorProps) {
  return (
    <Box>
      <Grid>
        <Grid.Col span={{ base: 9 }}>{children}</Grid.Col>

        <Grid.Col span={{ base: 3 }}>
          <Stack gap={16}>
            <SidebarElement title="Mode" tooltip="Pick a writing mode, based on how you feel like writing today">
              {/* TODO: Extract this into a ToggleButton group component */}
              <Button.Group>
                <Tooltip label="Write whatever you like, using your own inspiration.">
                  <Button
                    variant="default"
                    disabled={type === 'freeform'}
                    onClick={() => onChangeType('freeform')}
                    leftSection={<PencilIcon width={16} />}
                  >
                    Freeform
                  </Button>
                </Tooltip>
                <Tooltip label="Write using a daily prompt, if you're stuck for ideas!">
                  <Button
                    variant="default"
                    disabled={type === 'prompt'}
                    onClick={() => onChangeType('prompt')}
                    leftSection={<SparklesIcon width={16} />}
                  >
                    Prompt
                  </Button>
                </Tooltip>
              </Button.Group>
            </SidebarElement>

            {type === 'prompt' && (
              <SidebarElement
                title="Prompt"
                tooltip="A daily prompt generated for inspiration. Feel free to use it, or not use it!"
              >
                <CurrentPrompt />
              </SidebarElement>
            )}

            <SidebarElement title="Series" tooltip="Assign today's entry to a serial, e.g. My Sci-Fi Book">
              <SelectSerial onChange={onChangeSeries} value={serial} />
            </SidebarElement>

            <SidebarElement title="Tags" tooltip="Assign some tags, which can be used to organise your entries">
              <SelectTags onChange={onChangeTags} value={tags} />
            </SidebarElement>
          </Stack>
        </Grid.Col>
      </Grid>
    </Box>
  );
}

interface SidebarElementProps {
  children: React.ReactNode;
  title: string;
  tooltip: React.ReactNode;
}

const SidebarElement = ({ children, title, tooltip }: SidebarElementProps) => {
  return (
    <Stack gap={12}>
      <Tooltip label={tooltip}>
        <Group gap={8}>
          <InformationCircleIcon width={16} />

          <Text fw={600} size="sm">
            {title}
          </Text>
        </Group>
      </Tooltip>

      {children}
    </Stack>
  );
};

const CurrentPrompt = () => {
  const query = useQuery<Prompt>({
    queryKey: ['api', 'v1', 'prompts'],
    queryFn: () => fetcher('/api/v1/prompts'),
  });

  if (query.isLoading) {
    return <LoadingScreen />;
  }

  const prompt = query.data;
  if (!prompt || query.isError) {
    return <Text size="sm">Unable to generate a prompt!</Text>;
  }

  return <Text size="sm">{prompt.text}</Text>;
};
