import { Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { type Settings, fetcher } from '../../api';
import FormActions from '../FormActions';
import FormSubmit from '../FormSubmit';
import Page from '../Page';
import PageTitle from '../PageTitle';

export default function SettingsScreen() {
  const queryClient = useQueryClient();
  const query = useQuery<Settings>({
    queryKey: ['api', 'v1', 'settings'],
    queryFn: () => fetcher('/api/v1/settings'),
  });

  const patchSettings = useMutation({
    mutationFn: (values: typeof form.values) => {
      return fetcher('/api/v1/settings', {
        method: 'PATCH',
        body: JSON.stringify([
          {
            op: 'replace',
            path: '/reminder',
            value: Number.parseInt(values.reminder),
          },
        ]),
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['api', 'v1', 'settings'],
      });
      notifications.show({
        title: 'Settings saved',
        message: 'Your settings were saved!',
        color: 'green',
      });
    },
  });

  const form = useForm({
    mode: 'uncontrolled',
    // TODO: Do we need any validation here?
  });

  const settings = query.data;

  useEffect(() => {
    form.setValues({
      reminder: settings?.reminder?.toString() || '',
    });
  }, [form, settings]);

  if (query.isError) {
    // TODO
    return <div>Error!</div>;
  }

  const reminderTimes = [
    { label: 'Disable daily reminders', value: '' },
    { label: '12:00am', value: '0' },
    { label: '1:00am', value: '1' },
    { label: '2:00am', value: '2' },
    { label: '3:00am', value: '3' },
    { label: '4:00am', value: '4' },
    { label: '5:00am', value: '5' },
    { label: '6:00am', value: '6' },
    { label: '7:00am', value: '7' },
    { label: '8:00am', value: '8' },
    { label: '9:00am', value: '9' },
    { label: '10:00am', value: '10' },
    { label: '11:00am', value: '11' },
    { label: '12:00pm', value: '12' },
    { label: '1:00pm', value: '13' },
    { label: '2:00pm', value: '14' },
    { label: '3:00pm', value: '15' },
    { label: '4:00pm', value: '16' },
    { label: '5:00pm', value: '17' },
    { label: '6:00pm', value: '18' },
    { label: '7:00pm', value: '19' },
    { label: '8:00pm', value: '20' },
    { label: '9:00pm', value: '21' },
    { label: '10:00pm', value: '22' },
    { label: '11:00pm', value: '23' },
  ];

  return (
    <Page title={<PageTitle title="Settings" />}>
      <form onSubmit={form.onSubmit((e) => patchSettings.mutate(e))}>
        <Select
          key={form.key('reminder')}
          {...form.getInputProps('reminder')}
          disabled={patchSettings.isPending || query.isLoading}
          label="Daily reminder time"
          description="You'll receive a daily reminder at this time to write an entry"
          data={reminderTimes}
        />

        <FormActions>
          {/* TODO: This button doesn't appear unless you hover over it! */}
          <FormSubmit loading={patchSettings.isPending || query.isLoading}>Save</FormSubmit>
        </FormActions>
      </form>
    </Page>
  );
}
