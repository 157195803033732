import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { type Entry, fetcher } from '../../api';
import LoadingScreen from '../LoadingScreen';
import Page from '../Page';
import { EntryView } from './EntryView.tsx';

type EntryScreenParams = {
  id: string;
};

export default function EntryScreen() {
  // TODO: Use a loader?
  const { id } = useParams<EntryScreenParams>() as EntryScreenParams;
  const query = useQuery<Entry | undefined>({
    queryKey: ['api', 'v1', 'entries', id],
    queryFn: () => fetcher(`/api/v1/entries/${id}`),
  });

  if (query.isLoading) {
    return <LoadingScreen />;
  }

  if (query.isError) {
    // TODO
    return <div>Error loading entry</div>;
  }

  const entry = query.data;
  if (!entry) {
    // TODO
    return <div>Entry not found</div>;
  }

  // TODO: Expose these in the API as _meta
  // const dateBack = getDayBefore(date);
  // const dateNext = getDayAfter(date);

  return (
    <Page
      maxWidth="xl"
      title={
        <div />
        // TODO: Re-implement this
        // <Stack direction="row" justifyContent="space-between">
        //     <div>
        //         <Link to={`/entries/${dateBack}`}>
        //             &lt; {formatDate(dateBack)}
        //         </Link>
        //     </div>
        //     <div>
        //         <strong>
        //             {formatDate(date)}
        //             {date === getToday() && ' (today)'}
        //         </strong>
        //     </div>
        //     <div>
        //         <Link to={`/entries/${dateNext}`}>
        //             {formatDate(dateNext)} &gt;
        //         </Link>
        //     </div>
        // </Stack>
      }
    >
      <EntryView entry={entry} />
    </Page>
  );
}
