import { useAuthenticator } from '@aws-amplify/ui-react';
import { type FetchUserAttributesOutput, fetchUserAttributes, updateUserAttributes } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import type React from 'react';
import { useCallback } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

interface UpdateUserAttributes {
  name?: string;
}

const authContext = createContext<AuthContext>({
  loading: true,
  attributes: {},
  updateAttributes: async () => {},
  signOut: () => {},
});

interface ProvideAuthProps {
  children: React.ReactNode;
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: ProvideAuthProps) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

interface AuthContext {
  loading: boolean;
  attributes: FetchUserAttributesOutput;
  updateAttributes: (values: UpdateUserAttributes) => Promise<void>;
  signOut: () => void;
}

// Provider hook that creates auth object and handles state
function useProvideAuth(): AuthContext {
  const [loading, setLoading] = useState(true);
  const { signOut } = useAuthenticator((context) => [context.user]);
  const [attributes, setAttributes] = useState<FetchUserAttributesOutput>({});

  const fetchAttributes = useCallback(async () => {
    setLoading(true);
    const result = await fetchUserAttributes();
    setAttributes(result);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchAttributes();
    return () => {};
  }, [fetchAttributes]);

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    // TODO: Is this needed?
    const unsubscribe = Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signedIn':
          console.log('user have been signedIn successfully.');
          break;
        case 'signedOut':
          console.log('user have been signedOut successfully.');
          break;
        case 'tokenRefresh':
          console.log('auth tokens have been refreshed.');
          break;
        case 'tokenRefresh_failure':
          console.log('failure while refreshing auth tokens.');
          break;
        case 'signInWithRedirect':
          console.log('signInWithRedirect API has successfully been resolved.');
          break;
        case 'signInWithRedirect_failure':
          console.log('failure while trying to resolve signInWithRedirect API.');
          break;
        case 'customOAuthState':
          console.info('custom state returned from CognitoHosted UI');
          break;
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const updateAttributes = async (values: UpdateUserAttributes) => {
    await updateUserAttributes({
      userAttributes: {
        name: values.name,
      },
    });

    await fetchAttributes();
  };

  return {
    loading,
    attributes,
    updateAttributes,
    signOut,
  };
}
