import { PlusIcon, XMarkIcon } from '@heroicons/react/16/solid';
import { ActionIcon, Badge, Box, Button, Group, Modal, MultiSelect, Stack, TextInput, rgba } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { type Items, type Tag, fetcher } from '../../api.ts';
import FormSubmit from '../FormSubmit.tsx';

interface AddTagRequest {
  name: string;
}

interface AddTagModalProps {
  onSave: (id: number) => void;
}

export function AddTagModal({ onSave }: AddTagModalProps) {
  const queryClient = useQueryClient();
  const [opened, { open, close }] = useDisclosure(false);

  const initialValues: AddTagRequest = {
    name: '',
  };

  const form = useForm({
    mode: 'uncontrolled',
    initialValues,
    validate: {
      name: (value) => (!value ? 'Required' : null),
    },
  });

  const createTag = useMutation({
    mutationFn: (values: typeof form.values) => {
      return fetcher<Tag>('/api/v1/tags', {
        method: 'POST',
        body: JSON.stringify({
          name: values.name,
        }),
      });
    },
    onError: (error) => {
      console.error(error);
      notifications.show({
        title: 'Error',
        message: 'Failed to add tag. Please try again!',
        color: 'red',
      });
    },
    onSuccess: async (tag) => {
      form.reset();
      await queryClient.invalidateQueries({
        queryKey: ['api', 'v1', 'tags'],
      });
      onSave(tag.id);
      close();
    },
  });

  return (
    <>
      <Button variant="subtle" leftSection={<PlusIcon width={16} />} onClick={open}>
        Add
      </Button>

      <Modal centered opened={opened} onClose={close} title="Add new tag">
        <form onSubmit={form.onSubmit((e) => createTag.mutate(e))}>
          <Group grow preventGrowOverflow={false} justify="space-between">
            <TextInput data-autofocus key={form.key('name')} {...form.getInputProps('name')} />
            <FormSubmit loading={createTag.isPending}>Save</FormSubmit>
          </Group>
        </form>
      </Modal>
    </>
  );
}

interface SelectTagsProps {
  onChange: (option: number[]) => void;
  value: number[];
}

export default function SelectTags({ onChange, value }: SelectTagsProps) {
  const [selected, setSelected] = useState<number[]>(value);
  const query = useQuery<Items<Tag, void>>({
    queryKey: ['api', 'v1', 'tags'],
    queryFn: () => fetcher('/api/v1/tags'),
  });

  if (query.isError) {
    // TODO
    return <div>Error!</div>;
  }

  if (query.isLoading) {
    // TODO
    return <div>Loading...</div>;
  }

  const tags = query.data;
  if (!tags) {
    // TODO
    return <div>Something went wrong</div>;
  }

  const onChangeTags = (ids: number[]) => {
    setSelected(ids);
    onChange(ids);
  };

  const selectedTags = selected.map((id) => {
    const tag = tags.items.find((t) => t.id === id);
    if (!tag) {
      console.log('none', selected, tags.items);
      return <></>;
    }

    return (
      <Group key={id} gap={8}>
        <ActionIcon
          size="sm"
          variant="transparent"
          color="neutral"
          onClick={() => onChangeTags([...selected.filter((id) => id !== tag.id)])}
        >
          <XMarkIcon />
        </ActionIcon>

        <Badge
          style={{ backgroundColor: tag.colour_background, color: tag.colour_foreground }}
          variant="outline"
          styles={{
            root: {
              borderColor: rgba(tag.colour_foreground, 0.2),
            },
          }}
        >
          {tag.name}
        </Badge>
      </Group>
    );
  });

  const options = tags?.items.map((t) => ({
    value: t.id.toString(),
    label: t.name,
  }));

  return (
    <Box>
      <Group grow preventGrowOverflow={true}>
        <MultiSelect
          data={options}
          onChange={(v) => onChangeTags(v.map((id) => Number.parseInt(id)))}
          placeholder="Pick one or more tags"
          // TODO
          // renderValue={selected => `${selected.length} selected`}
          value={selected.map((id) => id.toString())}
        />

        <AddTagModal onSave={(id) => onChangeTags([...selected, id])} />
      </Group>

      <Stack gap={12} py={16}>
        {selectedTags}
      </Stack>
    </Box>
  );
}
